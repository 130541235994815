$primary-color: #007bff;
$text-color: #333;

.servicesContainer {
  display: flex; // Use flexbox for layout
  flex-wrap: wrap; // Allow wrapping for responsiveness
  // max-width: 1200px; // Optional max-width
//   margin: 20px auto; 
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%; // Make the container full width
@media screen and (max-width:768px) {
  width: auto;
}
  .formContainer {
    flex: 1; // Allow the form to take available space
    min-width: 300px; // Set a minimum width for the form
    margin-right: 20px; // Space between form and image

    h1 {
      text-align: center;
      color: $primary-color;
    }

    h2 {
      color: $primary-color;
    }

    p {
      margin-bottom: 15px;
      color: $text-color;
      line-height: 1.5;
    }

    .textArea {
      width: 100%;
      height: 150px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
      outline: none;
      margin-bottom: 10px;

      &:focus {
        border-color: $primary-color;
      }
    }

    .extractButton {
      background-color: $primary-color;
      color: white;
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    .resultContainer {
      margin-top: 20px;

      h3 {
        color: $primary-color;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          padding: 5px 0;
          color: $text-color;
        }
      }
    }
  }

  .imageContainer {
    flex: 1; // Allow the image to take available space
    min-width: 300px; // Set a minimum width for the image
    display: flex; // Use flexbox for alignment
    justify-content: center; // Center the image horizontally
    align-items: flex-end; // Align the image to the bottom

    .image {
      width: 100%; // Make the image responsive
      max-width: 400px; // Optional: limit max width for larger screens
      height: auto; // Maintain aspect ratio
      border-radius: 8px; // Optional: add border radius for styling
    }
  }
}
