$primary-color: #007bff;
$text-color: #333;

.privacyContainer {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
  h1 {
    color: $primary-color;
    text-align: center; 
    margin-bottom: 20px; 
  }

  h2 {
    color: $primary-color;
    margin-top: 20px; 
  }

  p {
    margin-bottom: 15px; 
    color: $text-color;
    line-height: 1.6; 
  }

  .policyGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;

    .policyCard {
      flex: 1 1 calc(33% - 20px); // Three columns with space
      margin: 10px; // Margin around each card
      background-color: #fff;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;

      &:hover {
        transform: translateY(-5px); // Hover effect
      }

      h2 {
        margin-top: 0; // Remove top margin for h2 in cards
      }
    }
  }
}
