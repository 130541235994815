$primary-color: #007bff;
$text-color: #333;

.contactContainer {
//   margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   max-width: 800px; // Optional max-width for larger screens
  text-align: center; // Center align text

  h1 {
    color: $primary-color;
    margin-bottom: 20px; // Space below the heading
  }

  .contactInfo {
    display: flex;
    flex-direction: column; // Stack items vertically
    align-items: center; // Center align items

    .contactItem {
      background-color: #ffffff; // White background for contrast
      border-radius: 8px; // Rounded corners
      padding: 15px;
      margin: 10px 0; // Space between items
      width: 100%; // Full width for the contact items
      max-width: 400px; // Optional max-width for smaller screens

      h2 {
        color: $primary-color;
        margin-bottom: 10px; // Space below subheading
      }

      p {
        margin: 0; // Remove default margin for paragraphs
        color: $text-color;
      }
    }
  }
}
