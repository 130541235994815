.footerContainer {
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #ccc;
}

.footerSection {
  flex: 1;
  min-width: 200px; // Ensures responsiveness
  margin: 10px;

  h3 {
    color: #007bff; // Primary color for headings
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin: 5px 0;

      a {
        text-decoration: none; // Remove underline
        color: #333; // Text color

        &:hover {
          color: #007bff; // Change color on hover
        }
      }
    }
  }

  .socialIcons {
    display: flex;

    .socialIcon {
      margin-right: 10px; // Space between icons
      color: #333; // Icon color
    }
  }
}
