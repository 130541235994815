.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff; // Adjust as needed
  position: relative;
height: 10vh;
  .logo {
    img {
      height: 100px; // Adjust based on your design
      width: 100%;
      @media screen and (max-width: 768px) {
        width: 180px;
      }
    }
  }

  .phoneNumber {
    flex-grow: 1; // This will make the phone number occupy the remaining space between logo and hamburger
    text-align: center; // Center the text
    a {
      text-decoration: none;
      color: #333; // Adjust color as needed
      // font-weight: bold;
      font-size: 3rem;
      @media screen and (max-width:768px) {
        font-size: medium;
      }
    }

  }

  .hamburger {
    cursor: pointer;
    display: none; // Hide by default, show on small screens

    @media (max-width: 768px) {
      display: block;
    }
  }

  .navLinks {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 1rem;
      font-size: 1.5rem;
      font-weight: bold;
@media screen and (max-width:768px) {
  font-size: medium;
}
      a {
        text-decoration: none;
        color: #333; // Adjust color as needed
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      position: absolute;
      top: 95px; // Adjust based on your logo height
      left: 0;
      right: 0;
      background-color: #fff;
      display: none; // Hide by default
      &.active {
        display: flex; // Show when active
        gap: 10px;
        text-align: center;
      }
    }
  }
}
