$email-verification-background: #f8f9fa;
$primary-color: #007bff;

.email-verification-info-container {
    // width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: $email-verification-background;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; // Stack on small screens
    align-items: center; // Center align items
    gap: 20px; // Space between sections

    @media (min-width: 768px) {
        flex-direction: row; // Row layout on larger screens
        justify-content: space-around; // Space out sections
    }

    .info-section {
        flex: 1; // Allow sections to grow equally
        padding: 10px;
        max-width: 300px; // Optional max-width for sections
        text-align: center; // Center text

        h1,
        h2 {
            color: $primary-color;
        }

        p {
            text-align: justify; // For better readability
            margin-bottom: 15px;
            line-height: 1.5;
        }

        ol {
            margin: 15px 0;
            padding-left: 20px;

            li {
                margin-bottom: 10px;
            }
        }
    }

    .contact-info {
        h2 {
            margin-bottom: 10px;
        }

        p {
            font-size: 1.2rem;
            //   color: $primary-color;
        }
    }
}