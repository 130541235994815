$primary-color: #007bff;
$text-color: #333;

.termsContainer {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
  h1 {
    color: $primary-color;
    text-align: center; 
    margin-bottom: 20px; 
  }

  h2 {
    color: $primary-color;
    margin-top: 20px; 
  }

  p {
    margin-bottom: 15px; 
    color: $text-color;
    line-height: 1.6; 
  }
}
