$email-input-bg: #f8f9fa;
$primary-color: #007bff;
$text-color: #333;

.email-verification-container {
    padding: 20px;
    background-color: $email-input-bg;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; // Center items horizontally

    h1 {
        text-align: center;
        color: $primary-color;
        margin-bottom: 20px;
        font-size: 3rem;
    }

    .email-input {
        width: 100%; // Full width for better responsiveness
        max-width: 400px; // Optional max width
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 10px;
        font-size: 1rem;

        &:focus {
            border-color: $primary-color;
        }
    }

    .submit-button {
        width: 100%; // Full width for better responsiveness
        max-width: 400px; // Optional max width
        background-color: $primary-color;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: darken($primary-color, 10%);
        }
    }

    .notice {
        text-align: center;
        margin-top: 10px;
        color: $text-color;
    }

    h2 {
        margin-top: 20px;
        color: $primary-color;
    }

    .result-textarea {
        width: 100%; // Full width for better responsiveness
        height: 50px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 10px;
        font-family: monospace; // For better readability of JSON
        background-color: #e9ecef; // Light background for textarea
        resize: none; // Prevent resizing
        color: $text-color;
        font-size: 0.9rem;
    }

    .contact-info {
        margin-top: 20px;
        text-align: center;

        h2 {
            margin-bottom: 10px;
        }
    }
}