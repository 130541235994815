$primary-color: #007bff;
$text-color: #333;

.aboutUsContainer {
//   margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; // Change to column to stack image below columns
  align-items: center; // Center align
 

  h1 {
    text-align: center;
    color: $primary-color;
    margin-bottom: 20px; // Add space below the heading
  }

  .columns {
    display: flex;
    justify-content: space-around; // Distribute columns evenly
    width: 100%; // Full width
    @media screen and (max-width:768px) {
      flex-direction: column;
    }

    .column {
      flex: 1; // Each column takes equal space
      margin: 0 10px; // Space between columns
      padding: 15px; // Inner padding
      background-color: #ffffff; // White background for contrast
      border-radius: 8px; // Rounded corners
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for depth

      h2 {
        color: $primary-color;
        margin-bottom: 10px; // Space below subheading
      }

      p {
        margin-bottom: 15px;
        color: $text-color;
        line-height: 1.6; // Improved readability
      }
    }
  }

  .imageContainer {
    margin-top: 20px; // Space above the image
    width: 100%; // Full width for the image container
    display: flex;
    justify-content: center; // Center the image

    .image {
      width: 60%; // Responsive width for the image
      max-width: 300px; // Optional max-width for larger screens
      height: auto; // Maintain aspect ratio
      border-radius: 8px; // Optional: add border radius for styling
    }
  }
}
